import { useCallback, useEffect, useReducer, useState, Dispatch } from "react";
import { Shift, Signup, QuestionAnswer } from "@amzn/red-velvet-api";
import { useUser } from "../user";
import { useSearchParams } from "react-router-dom";
import { loadEvents } from "../events";
import {
  eventsReducer,
  EventsModel,
  APPEND_MODEL,
  RELOAD_MODEL,
  SET_SIGNUP,
  SET_ALERT,
  AlertInfo,
  EventType,
  FullEvent
} from "../../hooks/betterEvents/reducer";
import { LoadedEvent, loadBetterEvents } from "./load";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { asDateString, asTimeString } from "../../utils/dateFormatting";
import { cancelSignup, signupUsertoShift } from "./signup";
import { featureIsEnabled, useFeatures } from "../features";
import { SIGNUP_DEADLINE_FEATURE_FLAG } from "../../utils/constants";
import { useQueryClient } from "@tanstack/react-query";

export interface UseBetterEventsModel {
  loading: boolean;
  events: EventsModel;
  ids: string[];
  total: number;
}
export interface UseBetterEventsController {
  cancelSignup(signup: Signup): Promise<void>;
  signupUserToShift(shift: Shift, alias: string, complexSignupInput?: ComplexSignupInputs): Promise<void>;
  clearAlert(eventId: string): void;
}

function asEventsModel(events: LoadedEvent[], t: TFunction<"translation", undefined>): EventsModel {
  const eventsModel: EventsModel = {};
  //add alert info
  events.forEach((details, index) => {
    const event = details.event;
    const shifts = details.shifts;
    const signups = details.signups;

    let alert: AlertInfo | undefined = undefined;
    if (event.status.type === "error") {
      alert = {
        type: "error",
        message: t("useEvents.loadErrorEvent", {
          id: details.eventId,
          reason: event.status.reason
        })
      };
    } else if (shifts.status.type === "error") {
      alert = {
        type: "error",
        message: t("useEvents.loadErrorShifts", {
          id: details.eventId,
          reason: shifts.status.reason
        })
      };
    } else if (signups.status.type === "error") {
      alert = {
        type: "error",
        message: t("useEvents.loadErrorSignups", {
          id: details.eventId,
          reason: signups.status.reason
        })
      };
    }
    eventsModel[details.eventId] = {
      event: event.value,
      shifts: shifts.value ?? [],
      signups: signups.value ?? [],
      alert
    };
  });
  return eventsModel;
}

const doCancelSignup = async (signup: Signup, dispatch: Dispatch<EventType>, t: TFunction<"translation", unknown>) => {
  try {
    await cancelSignup(signup);
    dispatch({
      type: SET_SIGNUP,
      eventId: signup.eventId,
      shiftId: signup.shiftId,
      userId: signup.userId,
      signup: undefined
    });
    dispatch({
      type: SET_ALERT,
      eventId: signup.eventId,
      alert: {
        type: "success",
        message: t("useEvents.signupCancelSuccess")
      }
    });
  } catch (error) {
    dispatch({
      type: SET_ALERT,
      eventId: signup.eventId,
      alert: {
        type: "error",
        message: t("useEvents.signupCancelError", { reason: `${error}` })
      }
    });
  }
};

const doSignupUserToShift = async (
  shift: Shift,
  userId: string,
  dispatch: Dispatch<EventType>,
  t: TFunction<"translation", unknown>,
  // I was unable to find a compatible and exported type for i18n and thus went with any, but eager for better ways
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  i18n: Record<string, any>,
  featureFlags: string[],
  complexSignup?: ComplexSignupInputs
) => {
  try {
    const result = await signupUsertoShift(userId, shift, featureFlags, complexSignup?.numberOfGuests, complexSignup?.tShirtSize, complexSignup?.questionAnswer, complexSignup?.waiverAccepted);
    dispatch({
      type: SET_SIGNUP,
      eventId: shift.eventId,
      shiftId: shift.shiftId,
      userId: userId,
      signup: {
        signupId: result.signupId,
        shiftId: shift.shiftId,
        userId: userId,
        eventId: shift.eventId,
        startDateTime: new Date(),
        guestCount: complexSignup?.numberOfGuests,
        tShirtSizeId: complexSignup?.tShirtSize,
        questionAnswers: complexSignup?.questionAnswer,
        waiverAccepted: complexSignup?.waiverAccepted
      }
    });
    dispatch({
      type: SET_ALERT,
      eventId: shift.eventId,
      alert: {
        type: "success",
        message: shift.startDateTime
          ? t("useEvents.signupSuccess", {
              shiftName: shift.name,
              shiftDate: asDateString(shift.startDateTime, undefined, i18n.language),
              shiftTime: asTimeString(shift.startDateTime, undefined, i18n.language)
            })
          : t("useEvents.ongoingSignupSuccess", { shiftName: shift.name })
      }
    });
  } catch (cause) {
    dispatch({
      type: SET_ALERT,
      eventId: shift.eventId,
      alert: {
        type: "error",
        message: t("useEvents.signupError", {
          shiftName: shift.name,
          reason: `${cause}`
        })
      }
    });
  }
};

interface UseSingleBetterEventModel {
  event?: FullEvent;
  loading: boolean;
}

export interface ComplexSignupInputs {
  numberOfGuests?: number;
  tShirtSize?: number;
  questionAnswer?: QuestionAnswer[];
  waiverAccepted?: boolean;
}

interface UseSingleBetterEventController {
  cancelSignup(signup: Signup): Promise<void>;
  signupUserToShift(shift: Shift, alias: string, complexSignup?: ComplexSignupInputs): Promise<void>;
  clearAlert(): void;
  reset(): void;
}

export function useSingleBetterEventFromSearchParam(): [UseSingleBetterEventModel, UseSingleBetterEventController] {
  const [searchParams] = useSearchParams();
  const user = useUser();
  const [loading, setLoading] = useState(true);
  const [model, dispatch] = useReducer(eventsReducer, { ids: [], events: {} });
  const [eventId, setEventId] = useState<string>();
  const { t, i18n } = useTranslation();
  const features = useFeatures();
  const queryClient = useQueryClient();

  const loadEvent = useCallback(async () => {
    const eventId = searchParams.get("id") ?? undefined;
    setEventId(eventId);
    if (eventId === undefined) {
      return;
    }
    if (user.userId === "") {
      return;
    }
    setLoading(true);
    try {
      const loadedEvents = await loadBetterEvents(queryClient, [eventId], user.userId);
      dispatch({
        type: "RELOAD_MODEL",
        model: {
          events: asEventsModel(loadedEvents, t),
          ids: [eventId]
        }
      });
    } finally {
      setLoading(false);
    }
  }, [user.userId, searchParams, t, queryClient]);

  useEffect(() => {
    loadEvent();
  }, [loadEvent]);

  const cancelSignup = useCallback(
    async (signup: Signup) => {
      return await doCancelSignup(signup, dispatch, t);
    },
    [dispatch, t]
  );

  const signupUserToShift = useCallback(
    async (shift: Shift, userId: string, complexSignup?: ComplexSignupInputs) => {
      const featureFlags = featureIsEnabled(SIGNUP_DEADLINE_FEATURE_FLAG, features, searchParams)
        ? [SIGNUP_DEADLINE_FEATURE_FLAG]
        : [];
      return await doSignupUserToShift(shift, userId, dispatch, t, i18n, featureFlags, complexSignup);
    },
    [features, searchParams, t, i18n]
  );

  const clearAlert = useCallback(() => {
    if (eventId === undefined) {
      return;
    }
    dispatch({
      type: SET_ALERT,
      eventId
    });
  }, [dispatch, eventId]);

  const reset = useCallback(() => {
    setEventId(undefined);
    dispatch({
      type: RELOAD_MODEL,
      model: {
        ids: [],
        events: {}
      }
    });
  }, [dispatch]);

  return [
    {
      loading,
      event: eventId ? model.events[eventId] ?? undefined : undefined
    },
    {
      reset,
      clearAlert,
      signupUserToShift,
      cancelSignup
    }
  ];
}

/**
 * 1. set to loading
 * 2. Load the summaries from the existing search API
 * 2. Pull the IDS and batch load all the event data from the new API
 * 3. Append/replace based on the current model for pagination
 * 4. Set loading to false
 * @returns
 */
export function useBetterEventsWithSearchParams(): [UseBetterEventsModel, UseBetterEventsController] {
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [searchParams] = useSearchParams();
  const user = useUser();
  const [model, dispatch] = useReducer(eventsReducer, { ids: [], events: {} });
  const { t, i18n } = useTranslation();
  const features = useFeatures();
  const queryClient = useQueryClient();

  const loadEventsWithSearchParams = useCallback(async () => {
    if (!user.userId) {
      return;
    }
    //If the page is unset we should reload rather than append because either
    //1. it is the first page anyway or
    //2. we change the search params which caused page to be cleared.

    //NOTE: Need to come back to this. It's too easy to get this wrong or do it differnt ways in different places (i.e.
    //I have to know what the default value of pagination is here and that has to stay the same)
    const pagination = searchParams.get("pagination") ?? "load";
    const onFirstPage = !searchParams.has("page") || searchParams.get("page") === "1";
    const shouldAppend = (pagination === "load" || pagination === "infinite") && !onFirstPage;

    setLoading(true);
    if (!shouldAppend) {
      dispatch({
        type: RELOAD_MODEL,
        model: {
          ids: [],
          events: {}
        }
      });
    }
    const newSummaries = await loadEvents(searchParams);
    const loadedEventIds = newSummaries.events.map((s) => `${s.id}`);
    const newEvents = asEventsModel(await loadBetterEvents(queryClient, loadedEventIds, user.userId), t);

    if (shouldAppend) {
      dispatch({
        type: APPEND_MODEL,
        model: {
          ids: loadedEventIds,
          events: newEvents
        }
      });
    } else {
      dispatch({
        type: RELOAD_MODEL,
        model: {
          ids: loadedEventIds,
          events: newEvents
        }
      });
    }
    //TODO: This si what the getEventsWith.. call does... is that what we want?
    //i.e. set total to the most recent load.
    setTotal(newSummaries.total);
    setLoading(false);
  }, [dispatch, setLoading, setTotal, searchParams, user.userId, t, queryClient]);

  const cancelSignup = useCallback(
    async (signup: Signup) => {
      return await doCancelSignup(signup, dispatch, t);
    },
    [dispatch, t]
  );

  const signupUserToShift = useCallback(
    async (shift: Shift, userId: string, complexSignup?: ComplexSignupInputs) => {
      const featureFlags = featureIsEnabled(SIGNUP_DEADLINE_FEATURE_FLAG, features, searchParams)
        ? [SIGNUP_DEADLINE_FEATURE_FLAG]
        : [];
      doSignupUserToShift(shift, userId, dispatch, t, i18n, featureFlags, complexSignup);
    },
    [features, searchParams, t, i18n]
  );

  const clearAlert = useCallback(
    (eventId: string) => {
      dispatch({
        type: SET_ALERT,
        eventId
      });
    },
    [dispatch]
  );

  useEffect(() => {
    loadEventsWithSearchParams();
  }, [loadEventsWithSearchParams]);

  return [
    { loading, events: model.events, ids: model.ids, total },
    { cancelSignup, signupUserToShift, clearAlert }
  ];
}
