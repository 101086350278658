import Header from "@amzn/awsui-components-react/polaris/header";
import { Shift, Signup, Event, Question, Option } from "@amzn/red-velvet-api";
import { useTranslation } from "react-i18next";
import { useTimezone } from "../../hooks/timezone";
import { asDateString, asTimeString } from "../../utils/dateFormatting";
import { getShiftLengthLabel } from "../eventDisplay";
import { ShiftActions } from "./actions";

export interface ModalData {
  loading: boolean,
  waiverLinkOpened: boolean,
  formValidated: boolean,
  questionAnswers?: {
    question: Question,
    options: Option[];
    answer?: Option
  }[]
}

export function ShiftHeader({ shift }: { shift: Shift }) {
    return <Header variant="h3">{shift.name}</Header>
}

export function ShiftBody({ event, shift }: { event: Event, shift: Shift }) {
    const { t, i18n } = useTranslation();
    const selectedTimezone = useTimezone();
    const hasLimitedSpots = shift.signupsRemaining && shift.maxSignup;

    const startDate = shift.startDateTime ? new Date(shift.startDateTime) : null;
    const endDate = shift.endDateTime ? new Date(shift.endDateTime) : null;

    const DateTimeShiftDisplay = startDate && endDate ? ()=>{
        const diffTime = endDate.getTime() - startDate.getTime();

        const timeString = asTimeString(startDate, selectedTimezone, i18n.language, {removeTimeZone:true});
        const localeStartDateString = asDateString(startDate, selectedTimezone, i18n.language);

        const eventLocalTimeStringNoTz = asTimeString(startDate, event.timeZone, i18n.language, {removeTimeZone:true});
        const eventLocalTimeString = asTimeString(startDate, event.timeZone, i18n.language, {removeTimeZone:true});
        const localTimeIdentical = eventLocalTimeStringNoTz === timeString;

        return <div>
            <p><b>{t("eventDetails.shiftDisplay.date")}:</b> {localeStartDateString}</p>
            <p><b>{t("eventDetails.shiftDisplay.startTime2")}:</b> {timeString}
                {localTimeIdentical ? <></> : <div className="shiftLocalDateTime"><small><b>{t("eventDetails.shiftDisplay.localStartTime")}:</b> {eventLocalTimeString}</small></div>}
            </p>
            <p><b>{t("eventDetails.shiftDisplay.length")}:</b> {getShiftLengthLabel(t, diffTime)}</p>
        </div>
    } : ()=>{
        return <p><b>{t("eventDetails.shiftDisplay.date")}:</b> {t("eventDisplay.ongoingEvent")}</p>;
    }


    return (
        //THIS CLASS IS REQUIRED FOR INTEGRATION TESTING TO REGISTER THE EXISTANCE OF SHIFTS
        <div className="shift">
            <div className="shiftBody">
                <DateTimeShiftDisplay/>
                {hasLimitedSpots ? <p>{t("eventDetails.shiftDisplay.signedUp")}: {(shift.maxSignup ?? 0) - (shift.signupsRemaining ?? 0)} </p> : <></>}
                <p><b>{t("eventDetails.shiftDisplay.spotsAvailable")}:</b> {hasLimitedSpots ? shift.signupsRemaining : t("eventDetails.shiftDisplay.unlimited")}</p>
            </div>
        </div>
    )
}

export type ShiftFooterProps = { 
    event: Event;
    shift: Shift;
    signup?: Signup;
};

export function ShiftFooter({ event, shift, signup}: ShiftFooterProps) {
    return (
        <div className="shiftFooter">
            <ShiftActions 
                event={event}
                shift={shift}
                signup={signup}/>
        </div>
    )
}
